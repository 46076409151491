import React from 'react';
import PropTypes from 'prop-types';
import { Button, Cushion, Image, Rectangle, Shadow, Space, Typography } from '@pitchero/react-ui';
import ExitIcon from '@pitchero/react-ui/dist/cjs/components/Icons/Exit';
import IconWithTheme from '../../icon-with-theme';
import { Trans } from '../../../lib/i18n';
import Nl2Br from '../../nl2br';

const Sponsor = ({ inPanel, sponsor, onReadMore }) => {
  const wrapWithLink = (child) => {
    if (sponsor.url) {
      return (
        <a href={sponsor.url} target="_blank" rel="nofollow noopener noreferrer">
          {child}
        </a>
      );
    }

    return child;
  };

  const charLimit = 150;
  const needsReadMore = !inPanel && sponsor.blurb && sponsor.blurb.length > charLimit;

  return (
    <Shadow>
      <Rectangle fill="white" radius="rounded" style={{ height: '100%' }} component="div">
        <Cushion horizontal="medium" vertical="medium">
          <Cushion bottom="small" style={{ textAlign: 'center' }}>
            {wrapWithLink(
              <Image src={sponsor.image} alt={sponsor.title} preset="sponsor" fullWidth />,
            )}
          </Cushion>
          <Cushion vertical="small">
            <Typography component="div" preset="subtitle--large" color="primary">
              {wrapWithLink(sponsor.title)}
            </Typography>
          </Cushion>
          <Typography component="div" preset="subtitle--small">
            {sponsor.subtitle}
          </Typography>
          {sponsor.blurb && (
            <Cushion top="small">
              <Typography component="div" preset="body--small" color="dovegrey">
                <Nl2Br>
                  {needsReadMore ? `${sponsor.blurb.substr(0, charLimit)}…` : sponsor.blurb}
                </Nl2Br>
              </Typography>
              {needsReadMore && (
                <Button preset="text" onClick={onReadMore} style={{ paddingLeft: 0 }}>
                  <Trans i18nKey="common:read_more">Read more</Trans>
                </Button>
              )}
            </Cushion>
          )}
          {sponsor.url &&
            wrapWithLink(
              <Space top="small">
                <Space right="xsmall">
                  <IconWithTheme>
                    <ExitIcon preset="button" color="primary" />
                  </IconWithTheme>
                </Space>
                <Typography preset="button" color="primary">
                  Visit website
                </Typography>
              </Space>,
            )}
        </Cushion>
      </Rectangle>
    </Shadow>
  );
};

Sponsor.propTypes = {
  inPanel: PropTypes.bool.isRequired,
  onReadMore: PropTypes.func.isRequired,
  sponsor: PropTypes.shape({
    blurb: PropTypes.string,
    image: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default Sponsor;
