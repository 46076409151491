import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Cushion, Rectangle, Typography, withTheme } from '@pitchero/react-ui';
import ArrowRightIcon from '@pitchero/react-ui/dist/cjs/components/Icons/ArrowRight';
import InfoCircleOutlineIcon from '@pitchero/react-ui/dist/cjs/components/Icons/InfoCircle';
import IconWithTheme from '../icon-with-theme';
import { Trans } from '../../lib/i18n';
import ClubLink from '../club-link';

const InformationBreadcrumb = ({ title, uiTheme }) => {
  const primaryColor = get(uiTheme, 'colors.primary');
  const hoverStyle = {
    transition: 'fill ease .5s',
    ':hover': {
      fill: primaryColor,
    },
  };

  return (
    <Rectangle fill="white" borderBottom="thinLightGrey">
      <Cushion vertical="medium" left="medium" component="div">
        <ClubLink route="information.index">
          <a>
            <Cushion right="small">
              <Typography preset="breadcrumb" color="dustygrey" style={hoverStyle}>
                <IconWithTheme>
                  <InfoCircleOutlineIcon preset="breadcrumb" />
                </IconWithTheme>
              </Typography>
            </Cushion>
          </a>
        </ClubLink>
        <IconWithTheme>
          <ArrowRightIcon size={10} color="dustygrey" />
        </IconWithTheme>
        <Cushion left="small">
          <a>
            <Typography preset="subtitle--small" color="primary">
              <Trans i18nKey={title}>{title}</Trans>
            </Typography>
          </a>
        </Cushion>
      </Cushion>
    </Rectangle>
  );
};

InformationBreadcrumb.propTypes = {
  title: PropTypes.string.isRequired,
  uiTheme: PropTypes.shape().isRequired,
};

export default withTheme(InformationBreadcrumb);
